import { useEffect } from "react";
import { useLocation } from "react-router";
import { pageView } from "../../services/analytics";

export function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    // Track page view here
    pageView();
  }, [location]);

  return null;
}
