import { GET_ERRORS, CLEAR_ERRORS } from "../types";

// RETURN ERRORS
export const returnErrors = (
  title: string,
  msg: string,
  status: string,
  id: string | null = null
) => {
  return {
    type: GET_ERRORS,
    payload: { title, msg, status, id },
  };
};

// CLEAR ERRORS
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
