import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaUserCheck } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./index.css";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { painAreas } from "../../services";
import SolidButton from "../../components/solid-btn";

function IntakeProcessSelection() {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { summary } = user;
  const actualPA = summary.painArea || "back";
  const [intakeName, setIntakeName] = useState("intake1");

  useEffect(() => {
    //get PA to different
    const getPa = async () => {
      try {
        const result = await painAreas.get();
        setIntakeName(result.data.find(e => e.Slug === summary.painArea)?.SurveyType || "intake1");
      } catch (error) {
        console.error("Error getting PA");
      }
    };
    getPa();
  }, []);

  return (
    <Container fluid className="intake-completed">
      <Row>
        <Col>
          <Row>
            <Col className="check-circle-container">
              <FaUserCheck />
            </Col>
          </Row>
          <Row className="title-row-intake">
            <Col>
              <h4>{t("intakeProcessSelection.review")}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="info">{t("intakeProcessSelection.looksLike")}</span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={{ span: 12 }} lg={{ span: 6 }} style={{ marginBottom: "16px" }}>
              <SolidButton label={"Review Prior Survey"} href={`survey/${actualPA}/${intakeName}`} state={{ samePainArea: true }} />{" "}
            </Col>
            <Col sm={{ span: 12 }} lg={{ span: 6 }}>
              <SolidButton label={"Apply for New Pain Area"} href={"pain-area-selection/"} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(IntakeProcessSelection);
