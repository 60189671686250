// FYI: Rekinetics is integrated to HealthJoy App (Webviews part) via iframe.
// So need ability to add interaction between them.
// We use "postMessage" method for add this ability.

let isHJIntegrationEnabled = false;

export const TYPES_OF_PUBLISH = {
  response: "response",
  history: "history",
  error: "error",
};

type PublishData = {
  type: string;
  data: object;
};

export const enableHJIntegration = () => {
  isHJIntegrationEnabled = true;
};

export const publishToHJ = (data: PublishData, origin?: string): void => {
  const targetOrigin = origin ?? "*";
  if (isHJIntegrationEnabled && window.parent !== window) {
    window.parent.postMessage(data, targetOrigin);
  }
};
