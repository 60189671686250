import React from "react";
import { Pagination, Row } from "react-bootstrap";

function PaginationNumbers(props: { pages?: number; totalItems: number; pageSize: number; setPage: (page: number) => void; page: number }) {
  const { setPage, page } = props;

  const calculatePages = () => Math.ceil(Math.max(props.totalItems / props.pageSize, 1));
  const pages = props.pages ? props.pages : calculatePages();
  const showNumberMin = Math.max(1, page - 5);
  const showNumberMax = Math.min(pages, page + 5);

  const generatePages = (): number[] => {
    const arr: number[] = [];
    for (let i = showNumberMin; i <= showNumberMax; i++) {
      arr.push(i);
    }
    return arr;
  };
  return (
    <Row className="justify-content-center">
      <Pagination>
        <Pagination.First onClick={() => setPage(1)} />
        <Pagination.Prev
          onClick={() => {
            setPage(Math.max(page - 1, 1));
          }}
        />
        {Math.max(1, page - 5) > 1 && <Pagination.Ellipsis onClick={() => {}} />}
        {[...generatePages()].map(e => (
          <Pagination.Item
            onClick={() => {
              setPage(e);
            }}
            key={e}
            active={e === page}
          >
            {e}
          </Pagination.Item>
        ))}
        {page < pages - 5 && (
          <>
            <Pagination.Ellipsis onClick={() => {}} />
            <Pagination.Item
              onClick={() => {
                setPage(pages);
              }}
              key={pages}
              active={pages === page}
            >
              {pages}
            </Pagination.Item>
          </>
        )}
        <Pagination.Next
          onClick={() => {
            setPage(Math.min(page + 1, pages));
          }}
        />
        <Pagination.Last
          onClick={() => {
            setPage(pages);
          }}
        />
      </Pagination>
    </Row>
  );
}

export default PaginationNumbers;
