import { SET_TOAST } from "../types";

export type toastPayload = {
  title?: string;
  body?: string;
  visible?: boolean;
  type?: string;
};

export const setToast = (data: toastPayload) => (dispatch) =>
  dispatch({ type: SET_TOAST, payload: data });
