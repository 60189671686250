import { RESET_SESSION, GET_PROGRAMS, GET_CURRENT_PROGRAM_DAY, GET_COMPLETED_PROGRAMS, MODAL_EVERY_FOUR_DAYS } from "../types";

const initialState = {
  programs: [],
  completedPrograms: [],
  currentProgramDay: 0,
  showEveryFourModal: {},
};

export default function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAMS:
      return {
        ...state,
        programs: action.payload,
      };
    case GET_CURRENT_PROGRAM_DAY:
      return {
        ...state,
        currentProgramDay: action.payload,
      };
    case GET_COMPLETED_PROGRAMS:
      return {
        ...state,
        completedPrograms: action.payload,
      };
    case RESET_SESSION:
      return initialState;
    case MODAL_EVERY_FOUR_DAYS:
      return {
        ...state,
        showEveryFourModal: action.payload,
      };
    default:
      return state;
  }
}
