import {
  RESET_SESSION,
  SET_SURVEY_RESPONSE,
  SENT_SURVEY,
  SENDING_SURVEY,
} from "../types";

const initialState = {
  surveys: {},
};

export default function surveyReducer(state: any = initialState, action) {
  switch (action.type) {
    case SET_SURVEY_RESPONSE:
      return {
        ...state,
        surveys: {
          [action?.payload?.survey]: {
            ...state.surveys[action?.payload?.survey],
            ...action?.payload?.data,
          },
        },
      };
    case SENDING_SURVEY:
      return {
        ...state,
        surveys: {
          [action?.payload]: {
            ...state.surveys[action?.payload],
            loading: true,
          },
        },
      };
    case SENT_SURVEY:
      return {
        ...state,
        surveys: {
          [action?.payload]: {
            ...state.surveys[action?.payload],
            loading: false,
          },
        },
      };
    case RESET_SESSION:
      return initialState;
    default:
      return state;
  }
}
