import React from "react";
import { Toast } from "react-bootstrap";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setToast } from "../../redux/actions/configActions";

function Index() {
  const dispatch = useDispatch();
  const config = useSelector((state: RootState) => state.config.toast);
  return (
    <div className={"toast--context"}>
      <Toast
        className={"toast--main"}
        onClose={() => dispatch(setToast({ visible: false }))}
        show={config.visible}
      >
        <Toast.Header>
          <div
            className={`toast__indicator toast__indicator--${config.type}`}
          />
          <strong className="mr-auto">{config.title} </strong>
          {config.subtitle ? <small>{config.subtitle}</small> : null}
        </Toast.Header>
        <Toast.Body>{config.body}</Toast.Body>
      </Toast>
    </div>
  );
}

export default Index;
