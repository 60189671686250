import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import "./index.css";

function IconAbsolute({ children }) {
  return <div style={{ position: "absolute", right: "1.25em", top: "1.25em", transform: "translateY('35px')" }}>{children}</div>;
}

function TextInputGroup(props) {
  const inputRef = useRef(null);

  useEffect(() => {
    // Moving cursor to the end
    if (props.inputType !== "email") {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [props.inputType]);

  return (
    <Form.Group controlId={props.controlId} className={props.className} style={props.style}>
      <div className="form-floating">
        <Form.Control
          id={props.controlId}
          required={props.required}
          type={props.inputType}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange ? e => props.onChange(e.target.value) : null}
          ref={inputRef}
          isInvalid={props.invalidMessage}
        />
        <label htmlFor={props.controlId}>{props.label}</label>
        <IconAbsolute>{props.icon}</IconAbsolute>
      </div>
      <Form.Control.Feedback type="invalid">{props.invalidMessage}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default TextInputGroup;
