import React, { FC } from "react";
import "./index.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../../routes/intake-survey/loading-spinner";

type SolidButtonInstance = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search?: any;
  style?: React.CSSProperties;
  variant?: string;
  className?: string;
  onClick?: () => void | Promise<void>;
  href?: string;
  loading?: boolean;
  extraProps?: Object;
  icon?: React.ReactElement;
  label?: string;
  children?: React.ReactChildren | React.ReactElement | string;
  type?: string;
  disabled?: boolean;
  iconRight?: React.ReactElement;
};
interface WrapperProps {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any;
  search: string;
}

const Wrapper: FC<WrapperProps> = ({ children, href, state, search }) => {
  return href ? <Link to={{ pathname: href, state, search }}>{children}</Link> : <>{children}</>;
};

function SolidButton(props: SolidButtonInstance) {
  return (
    <Wrapper href={props.href || ""} state={props.state} search={props.search}>
      <Button
        className={`solid-btn ${props.className || ""}`}
        variant={props.variant || "primary"}
        onClick={props.onClick}
        disabled={props.loading || props.disabled}
        type={props.type}
        style={props.style}
        {...props.extraProps}
      >
        {props.loading ? (
          <LoadingSpinner variant={"white"} />
        ) : (
          <>
            {props.icon} {props.label}
            {props.children}
            {props.iconRight && <span className="solid-btn-icon-right">{props.iconRight}</span>}
          </>
        )}
      </Button>
    </Wrapper>
  );
}

export default SolidButton;
