/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../../components/breadcrumbs/index.css";
import { ProgramIcon } from "../../components/icons";
import { Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { RiArrowRightSLine } from "react-icons/ri";
import "./index.css";
import "react-drop-zone/dist/styles.css";
import { StatusList } from "../../components/status-list";
import { usePagination } from "../../hooks/usePagination";
import Breadcrumbs from "../../components/breadcrumbs";
import ContainerWithBreadcrumbs from "../../components/container-with-breadcrumbs";
import PaginationNumbers from "../../components/pagination-numbers";

export const MembersStatusListRoute = () => {
  const { t } = useTranslation();
  const graduatedResponse = usePagination({
    hitsPerPage: 100,
    status: "GRADUATED",
    filterValues: null,
    requestPage: 1,
  });

  const droppedOutResponse = usePagination({
    hitsPerPage: 100,
    status: "DROPPED_OUT",
    filterValues: null,
    requestPage: 1,
  });

  const demoResponse = usePagination({
    hitsPerPage: 100,
    status: "DEMO",
    filterValues: null,
    requestPage: 1,
  });

  const pausedResponse = usePagination({
    hitsPerPage: 100,
    status: "PAUSED",
    filterValues: null,
    requestPage: 1,
  });

  const notAcceptedResponse = usePagination({
    hitsPerPage: 100,
    status: "NOT_ACCEPTED",
    filterValues: null,
    requestPage: 1,
  });

  const [graduatedList, setGraduatedList] = useState<any[]>([]);
  const [droppedOutList, setDroppedOutList] = useState<any[]>([]);
  const [demoList, setDemoList] = useState<any[]>([]);
  const [pausedList, setPausedList] = useState<any[]>([]);
  const [notAcceptedList, setNotAcceptedListPausedList] = useState<any[]>([]);

  useEffect(() => {
    setGraduatedList(graduatedResponse.hits);
    setDroppedOutList(droppedOutResponse.hits);
    setDemoList(demoResponse.hits);
    setPausedList(pausedResponse.hits);
    setNotAcceptedListPausedList(notAcceptedResponse.hits);
  }, [graduatedResponse, droppedOutResponse]);

  return (
    <ContainerWithBreadcrumbs fluid className="members-status-container">
      <Row>
        <Col>
          <Breadcrumbs>
            <LinkContainer to={"/admin"}>
              <Breadcrumb.Item>{t("Dashboard")}</Breadcrumb.Item>
            </LinkContainer>
            <span className="bc-caret">
              <RiArrowRightSLine />
              <RiArrowRightSLine className="caret-rt" />
            </span>
            <Breadcrumb.Item active>{t("members_status.title")}</Breadcrumb.Item>
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col className="members-status-title">
          <span>{t("members_status.title")}</span>
        </Col>
      </Row>
      <Row>
        <Col className="members-status-description-container">
          <p className="members-status-description">{t("members_status.description")}</p>
        </Col>
      </Row>
      <Row className="members-status-containers-row">
        <Col lg={{ span: 6 }} style={{ marginTop: "20px" }}>
          <div className="current-coach-container">
            <Row className="current-coach-row">
              <Col xs={{ span: 1, offset: 0 }} className="members-status-icon-circle-container">
                <div className="icon-circle">
                  <ProgramIcon />
                </div>
              </Col>
              <Col>
                <span className="current-coach-title">{t("members_status.graduated")}</span>
              </Col>
            </Row>
            <StatusList onSearch={graduatedResponse.search} searchValue={graduatedResponse.filter} members={graduatedList} />
            <PaginationNumbers
              setPage={graduatedResponse.setPage}
              page={graduatedResponse.page}
              totalItems={graduatedResponse.pages}
              pageSize={100}
            />
          </div>
        </Col>
        <Col lg={{ span: 6 }} style={{ marginTop: "20px" }}>
          <div className="current-coach-container">
            <Row className="current-coach-row">
              <Col xs={{ span: 1, offset: 0 }} className="members-status-icon-circle-container">
                <div className="icon-circle">
                  <ProgramIcon />
                </div>
              </Col>
              <Col>
                <span className="current-coach-title">{t("members_status.dropped_out")}</span>
              </Col>
            </Row>
            <StatusList onSearch={droppedOutResponse.search} searchValue={droppedOutResponse.filter} members={droppedOutList} />
            <PaginationNumbers
              setPage={droppedOutResponse.setPage}
              page={droppedOutResponse.page}
              totalItems={droppedOutResponse.pages}
              pageSize={100}
            />
          </div>
        </Col>
        <Col lg={{ span: 6 }} style={{ marginTop: "20px" }}>
          <div className="current-coach-container">
            <Row className="current-coach-row">
              <Col xs={{ span: 1, offset: 0 }} className="members-status-icon-circle-container">
                <div className="icon-circle">
                  <ProgramIcon />
                </div>
              </Col>
              <Col>
                <span className="current-coach-title">{t("members_status.demo")}</span>
              </Col>
            </Row>
            <StatusList onSearch={demoResponse.search} searchValue={demoResponse.filter} members={demoList} />
            <PaginationNumbers setPage={demoResponse.setPage} page={demoResponse.page} totalItems={demoResponse.pages} pageSize={100} />
          </div>
        </Col>
        <Col lg={{ span: 6 }} style={{ marginTop: "20px" }}>
          <div className="current-coach-container">
            <Row className="current-coach-row">
              <Col xs={{ span: 1, offset: 0 }} className="members-status-icon-circle-container">
                <div className="icon-circle">
                  <ProgramIcon />
                </div>
              </Col>
              <Col>
                <span className="current-coach-title">{t("members_status.paused")}</span>
              </Col>
            </Row>
            <StatusList onSearch={pausedResponse.search} searchValue={pausedResponse.filter} members={pausedList} />
            <PaginationNumbers
              setPage={pausedResponse.setPage}
              page={pausedResponse.page}
              totalItems={pausedResponse.pages}
              pageSize={100}
            />
          </div>
        </Col>
        <Col lg={{ span: 6 }} style={{ marginTop: "20px" }}>
          <div className="current-coach-container">
            <Row className="current-coach-row">
              <Col xs={{ span: 1, offset: 0 }} className="members-status-icon-circle-container">
                <div className="icon-circle">
                  <ProgramIcon />
                </div>
              </Col>
              <Col>
                <span className="current-coach-title">{t("members_status.not_accepted")}</span>
              </Col>
            </Row>
            <StatusList onSearch={notAcceptedResponse.search} searchValue={notAcceptedResponse.filter} members={notAcceptedList} />
            <PaginationNumbers
              setPage={notAcceptedResponse.setPage}
              page={notAcceptedResponse.page}
              totalItems={notAcceptedResponse.pages}
              pageSize={100}
            />
          </div>
        </Col>
      </Row>
    </ContainerWithBreadcrumbs>
  );
};
