import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Nav, Navbar, NavDropdown, Col, Row } from "react-bootstrap";
import NotificationCircle from "../navbar/notification-circle";
import { NotificationIcon, PowerIcon } from "../icons";
import MemberNavAccordion from "../navbar-accordion/member-notification-accordion";
import NavAccordion from "../navbar-accordion";
import { useLocation, useHistory, withRouter, NavLink } from "react-router-dom";
import "./index.css";
import { logout, updateSessionToken } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { DISPLAYED_CONTEXTS } from "../../constants";
import { LanguageSelector } from "../language-selector";

const NoNavbarRoutes = ["/intake-survey", "/login"];

type Notification = {
  by: string;
  when: string;
  title: string;
  icon: React.FC;
  notificationLocation: string;
  location: string;
  for: string;
};

function NavBar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [adminNotifications, setAdminNotifications] = useState<Notification[]>([]);
  const { user: currentUser, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { displayedContext } = useSelector((state: RootState) => state.general);

  const isHJContext = displayedContext === DISPLAYED_CONTEXTS.hj;

  const handleAdminNotificationRemoveClick = index => {
    const adminNotificationList = [...adminNotifications];
    adminNotificationList.splice(index, 1);
    setAdminNotifications(adminNotificationList);
  };

  const adminResult = adminNotifications.length;

  const [memberNotifications, setMemberNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    setInterval(() => {
      dispatch(updateSessionToken());
    }, 30000);
  }, []);

  const handleMemberNotificationRemoveClick = index => {
    const memberNotificationList = [...memberNotifications];
    memberNotificationList.splice(index, 1);
    setMemberNotifications(memberNotificationList);
  };

  const memberResult = memberNotifications.length;
  const location = useLocation();
  const history = useHistory();
  const onLogoutClicked = async () => {
    await dispatch(logout());
    history.push("/login");
  };

  if (!isAuthenticated || isHJContext) return null;

  if (NoNavbarRoutes.some(route => location.pathname.startsWith(route))) {
    return null;
  } else if (location.pathname.startsWith("/admin")) {
    return (
      //        ----admin navbar----
      <Navbar className="top-navbar" expand="lg">
        <Navbar.Brand href="/admin" className="navbar-logo">
          <img alt="logo" src="/images/logo.svg" height="30" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="margin-auto-class">
            <Nav.Link as={NavLink} className="programs-link" to="/admin/messenger">
              {t("navbar.messenger")}
            </Nav.Link>
            <Nav.Link as={NavLink} className="programs-link" to="/admin/programs">
              {t("navbar.programs")}
            </Nav.Link>

            <Nav.Link as={NavLink} className="dashboard-link" to="/admin/surveys" exact>
              {t("navbar.surveys")}
            </Nav.Link>

            <Nav.Link as={NavLink} className="dashboard-link" to="/admin" exact>
              {t("navbar.members")}
            </Nav.Link>

            <NavAccordion
              notifications={adminNotifications}
              onClick={handleAdminNotificationRemoveClick}
              navNotificationType={adminResult}
              className="sm-notification-icon-number"
            />

            <div className="justify-content-center mt-3 pb-0">
              <LanguageSelector vertical smallSize />
            </div>

            {/* Below Nav Accordion Nav Links */}
            <Nav.Link as={NavLink} className="logout-link" onClick={() => onLogoutClicked()} exact to={"/"}>
              <span className="logout-icon">
                <PowerIcon />
              </span>
              <span className="nav-dropdown-text">{t("navbar.log_out")}</span>
            </Nav.Link>

            <NavDropdown
              id={"avatar-dropdown"}
              title={currentUser && currentUser["given_name"] ? `${currentUser["given_name"][0]}${currentUser["family_name"][0]}` : ""}
              className="avatar-dropdown-container"
            >
              <div className="avatar-dropdown">
                <NavDropdown.Item className="logout-container" onClick={() => onLogoutClicked()}>
                  <span>
                    <PowerIcon />
                  </span>
                  <span className="nav-dropdown-text"> {t("navbar.log_out")}</span>
                </NavDropdown.Item>
              </div>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
  // NON ADMIN NAVBAR
  else {
    return (
      <Navbar className="top-navbar" expand="lg">
        <Navbar.Brand href="/" className="navbar-logo">
          <img alt="logo" src="/images/logo.svg" height="30" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="margin-auto-class">
            <Nav.Link as={NavLink} className="dashboard-link" to="/" exact>
              {t("navbar.dashboard")}
            </Nav.Link>
            <Nav.Link as={NavLink} className="programs-link" to="/programs">
              {t("navbar.programs")}
            </Nav.Link>

            <NavDropdown id={"notification-nav"} title={<NotificationIcon />} className="notification-nav-lg">
              <div className="notification-dropdown">
                <span className="dropdown-menu-arrow" />
                <NavDropdown.Item className="notification-container">
                  <Row>
                    <Col className="notification-orange-text-container">
                      {/* Notification Number */}
                      <span className="notification-orange-number-text">{memberResult}</span>
                      <span className="notification-orange-text">{t("navbar.new")}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="bottom-orange-notification-text-container">
                      <span className="bottom-orange-notification-text">{t("navbar.notification")}</span>
                    </Col>
                  </Row>
                </NavDropdown.Item>
              </div>
              <div className="white-notification-dropdown-container">
                <NavDropdown.Item>
                  {memberNotifications.map((notification, index) => (
                    <Nav.Link
                      key={index}
                      as={NavLink}
                      to={notification.location}
                      onClick={() => handleMemberNotificationRemoveClick(index)}
                    >
                      <Row>
                        <Col className="notification-dd-icon-container" lg={{ span: 2 }} md={{ span: 2 }} sm={{ span: 2 }} xs={{ span: 2 }}>
                          {notification.icon}
                        </Col>
                        <Col
                          className="notification-middle-text-container"
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          sm={{ span: 7 }}
                          xs={{ span: 7 }}
                        >
                          <Row className="notification-middle-text-row">
                            <Col>
                              <span className="notification-white-section-bold-text">{notification.title}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span className="notification-white-section-small-text">for {notification.for}</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="notification-right-text-container">
                          <span className="notification-white-section-time-text">{notification.when}</span>
                        </Col>
                      </Row>
                    </Nav.Link>
                  ))}
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <MemberNavAccordion
              notifications={memberNotifications}
              onClick={handleMemberNotificationRemoveClick}
              navNotificationType={memberResult}
              className="sm-notification-icon-number"
            />
            {/* Notification Number */}
            <NotificationCircle className="notification-icon-number" navNotificationType={memberResult} />

            <Nav.Link as={NavLink} className="logout-link" onClick={() => onLogoutClicked()} exact to={"/"}>
              <span className="logout-icon">
                <PowerIcon />
              </span>
              <span className="nav-dropdown-text">{t("navbar.log_out")}</span>
            </Nav.Link>

            <NavDropdown
              id={"avatar-dropdown-1"}
              title={currentUser && currentUser["given_name"] ? `${currentUser["given_name"][0]}${currentUser["family_name"][0]}` : ""}
              className="avatar-dropdown-container"
            >
              <div className="avatar-dropdown">
                <NavDropdown.Item className="logout-dd-container" onClick={() => onLogoutClicked()}>
                  <span>
                    <PowerIcon />
                  </span>
                  <span className="nav-dropdown-text">{t("navbar.log_out")}</span>
                </NavDropdown.Item>
              </div>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(NavBar);
