import { GET_CONFIGURATIONS, RESET_SESSION, SET_TOAST } from "../types";

const initialState = {
  configuration_list: [],
  toast: {
    visible: false,
    title: "",
    type: "",
    body: "",
  },
};

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOAST:
      return { ...state, toast: { ...state.toast, ...action.payload } };
    case GET_CONFIGURATIONS:
      return { ...state, configuration_list: action.payload };
    case RESET_SESSION:
      return initialState;
    default:
      return state;
  }
}
