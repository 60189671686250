import React from "react";
import "./index.css";

function NotificationCircle(props) {
  const hasNotifications = props.navNotificationType;
  if (hasNotifications === 0) {
    return null;
  }
  return (
    <div className={props.className}>
      {/* Notification Number */}
      <span>{props.navNotificationType}</span>
    </div>
  );
}

export default NotificationCircle;
