import {
  AUTHENTICATING,
  ERROR_TYPES,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  MONTHLY_MODAL,
  SELECTED_LANGUAGE,
  UPDATE_PAIN_AREA,
  UPDATE_APPLICATION_ACCEPTED,
  USER_LOADED,
  USER_LOADING,
  USER_TOKEN_UPDATE,
  UPDATE_SUMMARY_FAVORITE,
} from "../types";
import { returnErrors } from "./errorActions";
import { Authenticate, getAuthenticateData, LogOut, updateSession } from "../../utils/Authentication";
import { getPrograms } from "./programsActions";
import { mskApi } from "../../services/config";
import i18n from "../../i18n";
import moment from "moment";

import { TFavorite } from "../../types/type";
import { identifyUser, trackEvent } from "../../services/analytics";

type auth = {
  email?: string;
  code?: string;
};

type Nullable<T> = T | null;
let _currentExecution: Nullable<Promise<null>> = null;

const withSeriesExecution = func => async (dispatch, getState) => {
  if (_currentExecution) {
    try {
      await _currentExecution;
    } catch (e) {
      console.error(e);
    }
  }

  const executeFunc = async () => {
    let res;
    try {
      res = await func(dispatch, getState);
    } catch (e) {
      _currentExecution = null;
      throw e;
    }
    _currentExecution = null;
    return res;
  };
  _currentExecution = executeFunc();
  return await _currentExecution;
};

export const login =
  (props: { email?: string; code?: string } = {}) =>
  async dispatch => {
    const { email, code } = props;
    dispatch({ type: AUTHENTICATING });
    try {
      if (email || code) {
        const userTypeLogin = email?.includes("@") ? "email" : "phone";
        const [user, session, summary] = await Authenticate(email, code);
        identifyUser(user.attributes.sub);
        trackEvent("Web.MSK.Login.LoginSuccess");
        if (user && session && summary) {
          dispatch(setUserData({ user, session, summary, userTypeLogin }));
        } else {
          dispatch({ type: LOGIN_FAIL });
        }
      } else {
        dispatch(updateCurrentUser());
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      dispatch(returnErrors(ERROR_TYPES.AUTH_ERROR.title, e, "Active", ERROR_TYPES.AUTH_ERROR.code));
      dispatch({ type: LOGIN_FAIL });
      return ERROR_TYPES.AUTH_ERROR;
    }
  };

export const actualizeExtLoginState = ({ email, code }: auth) =>
  withSeriesExecution(async dispatch => {
    dispatch({ type: USER_LOADING });
    let authData;
    try {
      authData = await getAuthenticateData();
    } catch (e) {
      console.error("Failed to fetch auth data", e);
    }
    if (!authData || authData.some(val => !val)) {
      return dispatch(login({ email, code }));
    }
    const [user, session, summary] = authData;

    if (user.attributes.email !== email) {
      await LogOut();
      return dispatch(login({ email, code }));
    }

    return dispatch(setUserData({ user, session, summary }));
  });

export const updateSessionToken = () =>
  withSeriesExecution(async (dispatch, getState) => {
    try {
      const state = getState();
      if (state.auth?.user?.token) {
        const [session] = await updateSession();
        const newToken = session?.getAccessToken().getJwtToken();
        if (state.auth?.user?.token !== newToken) {
          return dispatch({
            type: USER_TOKEN_UPDATE,
            payload: {
              token: newToken,
            },
          });
        }
      }
    } catch (e) {
      console.error("Error updating token", e);
    }
  });

export const updateCurrentUser = (cb?) =>
  withSeriesExecution(async (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    try {
      const [user, session, summary] = await getAuthenticateData();
      if (!user || !session || !summary) {
        return dispatch({ type: LOGIN_FAIL });
      }
      if (getState) {
        //Launch modal monthly
        const isSameDayMonthly = () => {
          const oldTimestamp = localStorage.getItem("rkShowMonthlyModal");
          if (oldTimestamp) {
            const now = moment();
            const date = moment.unix(Number(oldTimestamp));
            localStorage.setItem("rkShowMonthlyModal", String(moment().unix()));
            return date.isSame(now, "day");
          }
          return null;
        };
        if (
          summary.data.currentWeek > 0 &&
          (summary.data.currentWeek / 4) % 1 === 0 &&
          !summary.data.monthlyAnswered &&
          !isSameDayMonthly()
        ) {
          dispatch({
            type: MONTHLY_MODAL,
            payload: {
              showMonthlyModal: true,
            },
          });
        }
      }
      dispatch({
        type: USER_LOADED,
        payload: {
          ...user.attributes,
          token: session?.accessToken?.jwtToken,
          summary: summary.data,
        },
      });
      cb && cb();
      return dispatch(setUserData({ user, session, summary }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      dispatch(
        returnErrors(
          ERROR_TYPES.AUTH_ERROR.title,
          e || "Something went wrong, please try again later",
          "Active",
          ERROR_TYPES.AUTH_ERROR.code
        )
      );
    }
    return dispatch({
      type: LOGIN_FAIL,
    });
  });

export const logout = () => async dispatch => {
  await LogOut();
  dispatch({ type: LOGOUT_SUCCESS });
};

export const setUserData = props => {
  const { user, session, summary } = props;
  return dispatch => {
    if (user) {
      window.analytics.identify(user.attributes.sub);
      //Old users sub remplace to old UserSub
      if (user.attributes["custom:UserSub"]) {
        user.attributes.sub = user.attributes["custom:UserSub"];
      }
      const payloadObject = {
        ...user.attributes,
        token: session?.accessToken?.jwtToken,
        summary: summary.data,
      };
      if (props.userTypeLogin) payloadObject.userTypeLogin = props.userTypeLogin;
      dispatch({
        type: LOGIN_SUCCESS,
        payload: payloadObject,
      });
      dispatch(
        getPrograms({
          currentPrograms: summary.data.currentProgram,
          completedPrograms: summary.data.completedPrograms,
          currentProgramDay: summary.data.currentProgramDays,
        })
      );
    }
  };
};

export const updateUserPainArea = (painArea: string, applicationSubmittedTimestamp: string) => dispatch => {
  dispatch({
    type: UPDATE_PAIN_AREA,
    payload: {
      painArea,
      applicationSubmittedTimestamp,
    },
  });
};

export const updateUserApplicationAccepted = (applicationAccepted: boolean) => dispatch => {
  dispatch({
    type: UPDATE_APPLICATION_ACCEPTED,
    payload: {
      applicationAccepted,
    },
  });
};

export const setSelectedLanguage = (lng: string) => {
  mskApi.setHeaders({ language: lng });
  localStorage.setItem("rkLanguage", lng);
  i18n.changeLanguage(lng);
  return {
    type: SELECTED_LANGUAGE,
    payload: {
      language: lng,
    },
  };
};

export const updateUserFavorite = (favorites: TFavorite) => ({
  type: UPDATE_SUMMARY_FAVORITE,
  payload: {
    favorites,
  },
});

export const updateSummaryPainArea = (paId?: number) =>
  withSeriesExecution(async (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    try {
      const [user, session, summary] = await getAuthenticateData(paId);

      if (getState) {
        //Launch modal monthly
        const isSameDayMonthly = () => {
          const oldTimestamp = localStorage.getItem("rkShowMonthlyModal");
          if (oldTimestamp) {
            const now = moment();
            const date = moment.unix(Number(oldTimestamp));
            localStorage.setItem("rkShowMonthlyModal", String(moment().unix()));
            return date.isSame(now, "day");
          }
          return null;
        };
        if (
          summary.data.currentWeek > 0 &&
          (summary.data.currentWeek / 4) % 1 === 0 &&
          !summary.data.monthlyAnswered &&
          !isSameDayMonthly()
        ) {
          dispatch({
            type: MONTHLY_MODAL,
            payload: {
              showMonthlyModal: true,
            },
          });
        }
      }
      dispatch({
        type: USER_LOADED,
        payload: {
          ...user.attributes,
          token: session?.accessToken?.jwtToken,
          summary: summary.data,
        },
      });
      return dispatch(setUserData({ user, session, summary }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      dispatch(
        returnErrors(
          ERROR_TYPES.AUTH_ERROR.title,
          e || "Something went wrong, please try again later",
          "Active",
          ERROR_TYPES.AUTH_ERROR.code
        )
      );
    }
  });
