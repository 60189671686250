import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DISPLAYED_CONTEXTS } from "../../constants";
import { RootState } from "../../redux/store";

const Breadcrumbs: React.FC<{ className?: string }> = ({ children, className }) => {
  const { displayedContext } = useSelector((state: RootState) => state.general);

  const isHJContext = displayedContext === DISPLAYED_CONTEXTS.hj;
  const _className = className || "";
  return <Breadcrumb className={(isHJContext ? "programs-breadcrumb-hj " : "programs-breadcrumb ") + _className}>{children}</Breadcrumb>;
};

export default Breadcrumbs;
