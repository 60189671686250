import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DISPLAYED_CONTEXTS } from "../../constants";
import { RootState } from "../../redux/store";

export default function ContainerWithBreadcrumbs(props) {
  const { displayedContext } = useSelector((state: RootState) => state.general);

  const isHJContext = displayedContext === DISPLAYED_CONTEXTS.hj;
  return (
    <Container {...props} style={isHJContext ? { marginTop: "40px" } : {}}>
      {props.children}
    </Container>
  );
}
