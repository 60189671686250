import { Header } from "../../constants";
import { ApisauceInstance, create } from "apisauce";
import { Api } from "./Api";
import { publishToHJ, TYPES_OF_PUBLISH } from "../../interaction";

export const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

class ApiManager {
  apiSauce: ApisauceInstance;
  header!: Object;
  constructor(base: string, headers: Header = DEFAULT_HEADERS) {
    this.apiSauce = create({
      baseURL: base,
      headers,
    });
    this.header = headers;
    this.apiSauce.setHeaders({
      authorization: localStorage.getItem("rkAccessToken") ?? "",
      language: localStorage.getItem("rkLanguage") ?? "en",
    });

    this.apiSauce.addMonitor(this.handleResponseError);
  }

  service(path: string) {
    return new Api(this.apiSauce, path, this.header);
  }

  setHeaders(headers: Header) {
    this.header = { ...this.header, ...headers };
    this.apiSauce.setHeaders(headers);
  }

  getHeaders() {
    return this.header;
  }

  handleResponseError({ problem, ...responseTraits }) {
    if (!problem) {
      return false;
    }

    publishToHJ({
      type: TYPES_OF_PUBLISH.error,
      data: {
        ...responseTraits,
        problem,
      },
    });
  }
}

export { ApiManager };
