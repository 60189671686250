import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSelectedLanguage } from "../../redux/actions/authActions";
import { trackEvent } from "../../services/analytics";

const LenguageSelectorFull = props => {
  const { changeLanguage, smallSize } = props;
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <Form.Group style={{ marginRight: "16px" }} controlId="LenguageSelect" className={props.className}>
      <Form.Control
        defaultValue={currentLanguage}
        onChange={event => {
          changeLanguage(event.target.value);
        }}
        as="select"
        style={{
          border: "1px solid #CDCED6",
          marginRight: "8px",
          borderRadius: "15px",
          padding: "10px",
          height: smallSize ? "45px" : "70px",
          width: "150px",
          appearance: "none",
          backgroundImage:
            // eslint-disable-next-line prettier/prettier
            "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"%238A51F6\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M6 9l6 6 6-6\"/></svg>')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 10px center",
          paddingRight: "30px",
        }}
      >
        <option style={{ margin: "10px", paddingRight: "10px" }} value={"es"}>
          🇪🇸 {t("Spanish")}
        </option>
        <option value={"en"}>🇺🇸 {t("English")}</option>
      </Form.Control>
    </Form.Group>
  );
};

export const LanguageSelector = props => {
  const { languageChangeTrigger, smallSize } = props;
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  const changeLanguage = (lng: string) => {
    if (lng !== currentLanguage) {
      trackEvent("Web.MSK.SurveyIntake.LanguageSelector.Change", { language: lng });
    }
    if (languageChangeTrigger) languageChangeTrigger();
    dispatch(setSelectedLanguage(lng));
  };
  useEffect(() => {
    changeLanguage(currentLanguage);
  }, [currentLanguage]);

  return (
    <>
      <LenguageSelectorFull smallSize={smallSize} changeLanguage={changeLanguage} {...props} />
    </>
  );
};
