import { mskApi } from "./config";

export const ADMIN_APPLICANTS_ENDPOINT = "/admin/applicants";
export const ADMIN_APPLICANTS_PAGINATED_ENDPOINT = "/admin/applicants-paginated";
export const ADMIN_COACHES_ENDPOINT = "/admin/coaches";
export const ADMIN_EXERCISES_ENDPOINT = "/admin/exercises";
export const ADMIN_MEMBERS_ENDPOINT = "/admin/members";
export const ADMIN_PROGRAMS_ENDPOINT = "/admin/programs";
export const ADMIN_USER_PROGRAM_ENDPOINT = "/admin/user-program";
export const ADMIN_SURVEY_ANSWER_ENDPOINT = "/members/survey-answers";
export const ADMIN_SURVEYS_ENDPOINT = "/admin/surveys";
export const ADMIN_COMUNICATIONS_ENDPOINT = "/admin/comunications/";
export const ADMIN_CHAT_TRANSCRIPTS_ENDPOINT = "/admin/chat-transcripts";
export const ADMIN_SMS_ENDPOINT = "/admin/sms";
export const ADMIN_IMPORTED_USERS = "/admin/user-imports";
export const ADMIN_IMPORT_USERS = "/admin/import_users";
export const ADMIN_COMPANIES = "/admin/companies";
export const ADMIN_DUPLICATED_USER_ENDPOINT = "/admin/duplicate-user";
export const MEMBER_PAIN_AREA_ENDPOINT = "/admin/member-pain-area";
export const EXERCISES_ENDPOINT = "/exercises";
export const LOGIN_ENDPOINT = "/login";
export const PROGRAMS_ENDPOINT = "/programs";
export const SURVEYS_ENDPOINT = "/surveys";
export const SURVEYS_ANSWERS_ENDPOINT = "/survey-answer";
export const SURVEY_INTAKE_ANSWERS_ENDPOINT = "/intake-answer";
export const SURVEYS_QUESTIONS_ENDPOINT = "/survey";
export const USER_SUMMARY_ENDPOINT = "/user/summary";
export const ADMIN_MEMBERS_PAGINATED_ENDPOINT = "/members-paginated";
export const INTAKE_SURVEYS_ENDPOINT = "/intake-surveys";
export const PAIN_AREAS_ENDPOINT = "/pain-areas";
export const SURVEY_ENDPOINT = "/survey";
export const PROGRAMS_FILTERED_ENDPOINT = "/programs-filtered";
export const IMPORT_USERS = "/bulk-users";
export const SEND_INVITE_USER = "/send-invite";
export const ACCEPT_TERMS_ENDPOINT = "/accept-terms";
export const MEMBER_PROGRAMS_ENDPOINT = "/member-programs";
export const MEMBER_PROGRAM_ENDPOINT = "/member-program";

export const surveys = mskApi.service(ADMIN_SURVEYS_ENDPOINT);
export const userSummary = mskApi.service(USER_SUMMARY_ENDPOINT);
export const programs = mskApi.service(PROGRAMS_ENDPOINT);
export const adminPrograms = mskApi.service(ADMIN_PROGRAMS_ENDPOINT);
export const adminUserProgram = mskApi.service(ADMIN_USER_PROGRAM_ENDPOINT);
export const adminCoaches = mskApi.service(ADMIN_COACHES_ENDPOINT);
export const adminMembers = mskApi.service(ADMIN_MEMBERS_ENDPOINT);
export const adminApplicants = mskApi.service(ADMIN_APPLICANTS_ENDPOINT);
export const adminApplicantsPaginated = mskApi.service(ADMIN_APPLICANTS_PAGINATED_ENDPOINT);
export const adminChatTranscripts = mskApi.service(ADMIN_CHAT_TRANSCRIPTS_ENDPOINT);
export const adminSMS = mskApi.service(ADMIN_SMS_ENDPOINT);
export const adminImportUsers = mskApi.service(ADMIN_IMPORTED_USERS);
export const adminImportPermissionUsers = mskApi.service(ADMIN_IMPORT_USERS);
export const adminCompanies = mskApi.service(ADMIN_COMPANIES);
export const adminComunications = mskApi.service(ADMIN_COMUNICATIONS_ENDPOINT);
export const adminDuplicatedUser = mskApi.service(ADMIN_DUPLICATED_USER_ENDPOINT);
export const memberPrograms = mskApi.service(MEMBER_PROGRAMS_ENDPOINT);
export const memberProgram = mskApi.service(MEMBER_PROGRAM_ENDPOINT);
export const memberPainArea = mskApi.service(MEMBER_PAIN_AREA_ENDPOINT);
export const userExercises = mskApi.service(EXERCISES_ENDPOINT);
export const exercises = mskApi.service(ADMIN_EXERCISES_ENDPOINT);
export const loginService = mskApi.service(LOGIN_ENDPOINT);
export const userSurvey = mskApi.service(SURVEYS_ENDPOINT);
export const surveyAnswer = mskApi.service(ADMIN_SURVEY_ANSWER_ENDPOINT);
export const surveysAnswers = mskApi.service(SURVEYS_ANSWERS_ENDPOINT);
export const surveyIntakeAnswer = mskApi.service(SURVEY_INTAKE_ANSWERS_ENDPOINT);
export const surveysQuestions = mskApi.service(SURVEYS_QUESTIONS_ENDPOINT);
export const survey = mskApi.service(SURVEY_ENDPOINT);
export const adminMembersPaginated = mskApi.service(ADMIN_MEMBERS_PAGINATED_ENDPOINT);
export const painAreas = mskApi.service(PAIN_AREAS_ENDPOINT);
export const programsFiltered = mskApi.service(PROGRAMS_FILTERED_ENDPOINT);
export const importUsers = mskApi.service(IMPORT_USERS);
export const sendInviteUser = mskApi.service(SEND_INVITE_USER);
export const acceptTerms = mskApi.service(ACCEPT_TERMS_ENDPOINT);
