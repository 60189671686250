import { configureStore } from "@reduxjs/toolkit";

import errorsReducer from "./reducers/errorsReducer";
import configReducer from "./reducers/configReducer";
import { authReducer } from "./reducers/authReducer";
import surveyReducer from "./reducers/surveyReducer";
import programsReducer from "./reducers/programsReducer";
import generalReducer from "./reducers/generalReducer";

export const store = configureStore({
  reducer: {
    error: errorsReducer,
    config: configReducer,
    auth: authReducer,
    programs: programsReducer,
    surveys: surveyReducer,
    general: generalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
