export const trackEvent = (event, properties = {}) => {
  if (window.analytics) {
    window.analytics.track(event, properties);
  } else {
    console.warn("Segment not loaded yet");
  }
};

export const identifyUser = (userId, traits = {}) => {
  window.analytics.identify(userId, traits);
};

export const pageView = () => {
  window.analytics.page();
};
