import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import { Col, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { SurveyIcon } from "../icons";
import { Link } from "react-router-dom";
import moment from "moment";
import TextInputGroup from "../text-input-group";

export const StatusList = ({ members, onSearch, searchValue }) => {
  const { t } = useTranslation();
  const [formattedMembers, setFormattedMembers] = useState([]);
  const timeOutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [valueQuery, setValueQuery] = useState(searchValue);

  useEffect(() => {
    const tempMembers = members.map(el => ({
      name: `${el.firstName} ${el.lastName}`,
      ...el,
    }));
    setFormattedMembers(tempMembers);
  }, [members]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      searchable: true,
      sort: true,
      sortCaret: order => {
        if (order === "asc") {
          return <FaSortDown style={{ fill: "black", marginLeft: "15px" }} />;
        } else if (order === "desc") {
          return <FaSortUp style={{ fill: "black", marginLeft: "15px" }} />;
        } else {
          return <FaSort style={{ marginLeft: "15px" }} />;
        }
      },
      formatter: (cell, row) => (
        <Link to={`/admin/profile/${row.userId}/${row.memberPaId}`}>
          <span className="admin-table-name">
            {row.firstName} {row.lastName}
          </span>
        </Link>
      ),
    },
    {
      dataField: "lastLogin",
      text: "Last Login",
      searchable: true,
      sort: true,
      sortCaret: order => {
        if (order === "asc") {
          return <FaSortDown style={{ fill: "black", marginLeft: "15px" }} />;
        } else if (order === "desc") {
          return <FaSortUp style={{ fill: "black", marginLeft: "15px" }} />;
        } else {
          return <FaSort style={{ marginLeft: "15px" }} />;
        }
      },

      formatter: (cell, row) => <span className="admin-table-name">{moment.unix(row.lastLogin).format("MM/DD/yyyy")}</span>,
    },
    {
      dataField: "",
      text: "",
      isDummyField: true,
      searchable: false,
      headerStyle: { width: "8em" },
      formatter: (cell, row) => (
        <Link to={`/admin/profile/${row.userId}/${row.memberPaId}`}>
          <SurveyIcon />
        </Link>
      ),
      csvExport: false,
    },
  ];

  const onChangeKeyboardDelay = e => {
    setValueQuery(e);
    timeOutRef.current && clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      onSearch(e);
    }, 1000);
  };

  return (
    <ToolkitProvider keyField="id" data={formattedMembers} columns={columns} search>
      {props => (
        <>
          <Row>
            <Col>
              <TextInputGroup
                value={valueQuery}
                type="search"
                placeholder={t("applicant_dashboard.start_typing")}
                onChange={onChangeKeyboardDelay}
              />
            </Col>
          </Row>
          <BootstrapTable
            {...props.baseProps}
            keyField="id"
            data={formattedMembers}
            columns={columns}
            defaultSorted={[
              {
                dataField: "creationTimestamp",
                order: "desc",
              },
            ]}
            classes="coach-table__table--un-styled"
            bodyClasses="coach-table__table--un-styled"
            wrapperClasses="coach-table__table--un-styled"
          />
        </>
      )}
    </ToolkitProvider>
  );
};

export default StatusList;
