import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";

declare type Color = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "dark" | "light" | "white" | "muted";

declare type LoadingInstance = {
  variant: Color;
};

export const LoadingSpinner = ({ variant }: LoadingInstance) => (
  <Container>
    <Row className="show-grid">
      <Col xs={12}>
        <Spinner animation="border" variant={variant || "primary"} />
      </Col>
    </Row>
  </Container>
);
