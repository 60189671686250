import React from "react";
import Spinner from "react-spinkit";
import "./index.css";

function PageLoader() {
  return (
    <div className="spinner-container">
      <Spinner name="pacman" color="#1051cb" fadeIn="none" />
    </div>
  );
}

export default PageLoader;
