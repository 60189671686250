import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import PageLoader from "../components/page-loader";
import { useHistory } from "react-router-dom";
import { updateCurrentUser } from "../redux/actions/authActions";

export const Admin = BaseComponent => {
  const AdminComponent = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isAuthenticated, isLoading, user, attempts } = useSelector((state: RootState) => state.auth);

    const checkPermissions = useCallback(() => {
      if (attempts > 0 && !isLoading) {
        if (!isAuthenticated) {
          history.push("/login");
          return;
        }
        if (isAuthenticated && user["custom:isAdmin"] === "true") return;
        if (!isAuthenticated) {
          history.push("/login");
          return;
        }
        if (isAuthenticated && user["custom:isAdmin"] !== "true") {
          history.push("/");
          return;
        }
      }
    }, [attempts, isAuthenticated, user, history, isLoading]);

    useEffect(() => {
      checkPermissions();
    }, [attempts, checkPermissions, user, isAuthenticated, isLoading]);

    useEffect(() => {
      if (!isAuthenticated) {
        dispatch(updateCurrentUser());
      }
    }, []);

    if (isLoading) {
      return <PageLoader />;
    } else {
      return isAuthenticated && user["custom:isAdmin"] === "true" ? <BaseComponent {...props} /> : <PageLoader />;
    }
  };
  return AdminComponent;
};
