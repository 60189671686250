import moment from "moment";
import { MONTHLY_MODAL, SET_DISPLAY_CONTEXT, UTM_FIELDS } from "../types";

export const setDisplayContext = context => {
  return { type: SET_DISPLAY_CONTEXT, payload: { context } };
};

export const setShowMonthlyModal = (show: boolean) => {
  localStorage.setItem("rkShowMonthlyModal", String(moment().unix()));
  return {
    type: MONTHLY_MODAL,
    payload: {
      showMonthlyModal: show,
    },
  };
};

export const updateUtmParameters = (utmFields: Object) => {
  return {
    type: UTM_FIELDS,
    payload: {
      ...utmFields,
    },
  };
};

export const resetDisplayContext = () => {
  return setDisplayContext(null);
};
