export const getInitialValues = (sections = []) => {
  const values = {
    "free-text": {
      value: "",
      type: "free-text",
    },
    "sliding-scale": {
      value: 5,
      type: "sliding-scale",
    },
    "multiple-choice": { value: [], type: "multiple-choice" },
  };

  const result = sections.map(({ Questions }) =>
    Questions.reduce((acc, el) => {
      const choices = el?.Choices || el?.choices;
      if (["free-text"].includes(el.Type)) {
        return {
          ...acc,
          [el.Title]: values[el.Type],
        };
      }
      if (["sliding-scale"].includes(el.Type)) {
        return {
          ...acc,
          [el.Title]: (el.Format || el.format) === "percentage" ? 50 : values[el.Type],
        };
      }
      if ("multiple-choice" === el.Type) {
        return {
          ...acc,
          [el.Title]: {
            type: el.Type,
            value: el?.format === "radio" ? "" : choices.map(choice => ({ title: choice, value: false })),
          },
        };
      }
      return acc;
    }, {})
  );

  return result.reduce((acc, el) => ({ ...acc, ...el }), {});
};

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = phoneNumberString.toString().replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

// eslint-disable-next-line quotes
export const getQueryParams = text => JSON.parse(`{"${decodeURI(text.replace(/&/g, '","').replace(/=/g, '":"').replace("?", ""))}"}`);

export const arrayReplace = (arr, keySelector, newValue) => {
  const newArray = JSON.parse(JSON.stringify(arr));
  const index = newArray.indexOf(el => {
    return keySelector === "Object" ? el[keySelector["key"]] === keySelector["value"] : keySelector === el;
  });
  newArray[index] = newValue;
  return newArray;
};

export function validateAnswers(questions, answers, getQuestionAnswer) {
  for (let i = 0; i < questions.length; i++) {
    const response = getQuestionAnswer(questions[i]);
    if (!(response === answers)) return false;
  }
  return true;
}

/** Given a number 0 to 10 transform to string "Satisfied", "Neutral" or "Dissatisfied".
 *
 * @param {number} satisfactionNumber  - Number from 0 to 10.
 *
 * @returns {string} Satisfied, Neutral or Dissatisfied.
 */
export const satisfactionNumberToText = satisfactionNumber => {
  if (satisfactionNumber > 7) {
    return "Satisfied";
  }
  if (satisfactionNumber >= 3) {
    return "Neutral";
  }
  return "Dissatisfied";
};

export const surveyScoreGeneralizedAnxietyDisorderOrPatientHealthQuestionnaireToText = scoreNumber => {
  if (scoreNumber >= 15) {
    return "Severe clinical significance";
  }
  if (scoreNumber >= 10) {
    return "Moderate clinical significance";
  }
  if (scoreNumber >= 5) {
    return "Mild clinical significance";
  }
  return "None";
};

/** Order array of strings.
 *
 * @param {string} a - First string.
 * @param {string} b  - Second string.
 * @param {string} order - Accepts "asc" or "desc".
 *
 * @returns {number} -1, 0 or 1.
 */
export const sortWords = (a, b, order) => {
  const calculate_diff = () => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };
  if (order === "asc") {
    return calculate_diff();
  }
  return calculate_diff() * -1;
};

export const moreThan5Weeks = unixTimestamp => {
  // Check than we are receiving a value
  if (!unixTimestamp) {
    return false;
  }
  // Calculate the current time in Unix timestamp
  const currentTime = Math.floor(Date.now() / 1000);

  // Calculate the time difference in seconds
  const timeDifference = currentTime - unixTimestamp;

  // Calculate the number of weeks in seconds
  const weeksInSeconds = 5 * 7 * 24 * 60 * 60;

  // Compare the time difference with 5 weeks in seconds
  return timeDifference > weeksInSeconds;
};
