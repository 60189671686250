import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { parse } from "query-string";
import { actualizeExtLoginState } from "../redux/actions/authActions";
import { setDisplayContext, updateUtmParameters } from "../redux/actions/generalActions";
import { DISPLAYED_CONTEXTS } from "../constants";
import { enableHJIntegration } from "../interaction";
import Notification from "../components/notification";

function Guard({ children }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchObject: any = parse(window.location.search);
  const { consentAuthKey: code, email, displayedContext } = searchObject;
  const utmParameters = Object.keys(searchObject).filter(k => k.startsWith("utm_"));

  const dispatch = useDispatch();

  if (displayedContext) {
    dispatch(setDisplayContext(displayedContext));
  }

  if (displayedContext === DISPLAYED_CONTEXTS.hj) {
    enableHJIntegration();
  }

  useEffect(() => {
    if (displayedContext && email && code) {
      dispatch(actualizeExtLoginState({ email, code }));
    }
    //Contains utm parameters
    if (Object.keys(utmParameters).length !== 0) {
      const utmObject = utmParameters.reduce((cur, key) => {
        return Object.assign(cur, { [key]: searchObject[key] });
      }, {});
      dispatch(updateUtmParameters(utmObject));
    }
  }, [displayedContext, code, email, dispatch]);

  return (
    <>
      {children}
      <Notification />
    </>
  );
}

export default Guard;
