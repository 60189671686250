import { CLEAR_ERRORS, GET_ERRORS, RESET_SESSION } from "../types";

const initialState = {
  msg: null,
  status: null,
  title: null,
  id: null,
};

export default function errorsReducer(
  state = initialState,
  action
):
  | {
      msg: null;
      status: null;
      id: null;
      title: null;
    }
  | undefined {
  switch (action.type) {
    case GET_ERRORS:
      return {
        msg: action.payload.msg,
        status: action.payload.status,
        id: action.payload.id,
        title: null,
      };
    case CLEAR_ERRORS:
      return state;
    case RESET_SESSION:
      return initialState;
    default:
      return state;
  }
}
