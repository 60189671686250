import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useHistory } from "react-router-dom";
import PageLoader from "../components/page-loader";

const UnAuthenticated = (BaseComponent) => {
  const AdminComponent = (props) => {
    const history = useHistory();
    const { isAuthenticated, isLoading } = useSelector(
      (state: RootState) => state.auth
    );
    const checkPermissions = useCallback(() => {
      if (isAuthenticated && !isLoading) history.push("/");
    }, [history, isAuthenticated, isLoading]);

    useEffect(() => {
      checkPermissions();
    }, [checkPermissions]);

    if (!isAuthenticated) return <BaseComponent {...props} />;
    return <PageLoader />;
  };
  return AdminComponent;
};

export default UnAuthenticated;
