import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionContext, Card, Col, Row, useAccordionToggle } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import NotificationCircle from "../navbar/notification-circle";
import { NotificationIcon } from "../icons";
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

function NavAccordionToggle({ eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return <div onClick={decoratedOnClick}>{isCurrentEventKey ? <RiArrowDownSLine /> : <RiArrowRightSLine />}</div>;
}

function NavAccordion(props) {
  const { t } = useTranslation();
  return (
    <Row className="nav-accordion-row">
      <Col className="nav-accordion-col" lg={{ span: 12 }}>
        <Accordion defaultActiveKey="1">
          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
              <Row className="nav-accordion-row">
                <Col>
                  <Row>
                    <Col className="nav-accordion-col">
                      <Row>
                        <Col>
                          <NotificationCircle className="sm-notification-icon-number" navNotificationType={props.navNotificationType} />
                          <span className="not-icon-container">
                            <NotificationIcon />
                          </span>
                          <span className="accordion-not-text">{t("navbar.notifications")}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="notification-accordion-icon-container">
                      <span className="icon">
                        <NavAccordionToggle eventKey="0" className="icon" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse className="accordion-collapse" eventKey="0">
              <Card.Body>
                <Row className="notification-dropdown-container">
                  <Col>
                    {props.notifications.map((notification, index) => (
                      <LinkContainer key={index} to={notification.notificationLocation} onClick={() => props.onClick(index)}>
                        <Row className="notification-dropdown-row-container">
                          <Col
                            className="notification-dd-icon-container"
                            lg={{ span: 2 }}
                            md={{ span: 2, offset: 1 }}
                            sm={{ span: 2, offset: 1 }}
                            xs={{ span: 2, offset: 1 }}
                          >
                            {notification.icon}
                          </Col>
                          <Col
                            className="notification-middle-text-container"
                            lg={{ span: 7 }}
                            md={{ span: 3 }}
                            sm={{ span: 3 }}
                            xs={{ span: 4 }}
                          >
                            <Row>
                              <Col>
                                <span className="notification-bold-text">{notification.title}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className="notification-white-section-small-text">
                                  {t("navbar.by")} {notification.by}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={{ span: 2 }} sm={{ span: 2 }} xs={{ span: 2, offset: 1 }} className="notification-right-text-container">
                            <span className="notification-white-section-time-text">{notification.when}</span>
                          </Col>
                        </Row>
                      </LinkContainer>
                    ))}
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
    </Row>
  );
}

export default NavAccordion;
