import { TCompany } from "../../routes/admin-companies/components/addCompanyModal";
import { DTO } from "./DTOInterface";

export interface CompaniesListDTO {
  page: number;
  page_size: number;
  total_items: number;
  items: CompaniesDTO[];
}

export interface CompaniesDTO {
  slug: string;
  deleted: boolean;
  additional_terms: boolean;
  company_name: string;
  is_standalone: boolean;
}

export class CompaniesListDTOTransformer implements DTO {
  DTO: CompaniesListDTO;
  constructor(CompaniesListDTO: CompaniesListDTO) {
    this.DTO = CompaniesListDTO;
  }

  getOriginalDTO() {
    return this.DTO;
  }

  getModel() {
    return this.DTO?.items?.map(CompaniesListDTOTransformer.convertCompanies);
  }

  static convertCompanies(companyItem: CompaniesDTO): TCompany {
    return {
      AdditionalTerms: companyItem.additional_terms,
      CompanyName: companyItem.company_name,
      Slug: companyItem.slug,
      IsStandalone: companyItem.is_standalone,
    };
  }
}
