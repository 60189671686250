import { MONTHLY_MODAL, SET_DISPLAY_CONTEXT, UTM_FIELDS } from "../types";
import { STORAGE_DISPLAY_CONTEXT_KEY } from "../../constants";

const initialState = {
  displayedContext: localStorage.getItem(STORAGE_DISPLAY_CONTEXT_KEY),
  showMonthlyModal: false,
  utmObject: {},
};

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DISPLAY_CONTEXT:
      // eslint-disable-next-line no-case-declarations
      const { context } = action.payload;
      localStorage.setItem(STORAGE_DISPLAY_CONTEXT_KEY, context);
      return { ...state, displayedContext: context };
    case MONTHLY_MODAL:
      return { ...state, showMonthlyModal: action.payload.showMonthlyModal };
    case UTM_FIELDS:
      return { ...state, utmObject: action.payload };
    default:
      return state;
  }
}
