import { memberProgram } from "../../services";
import { GET_COMPLETED_PROGRAMS, GET_CURRENT_PROGRAM_DAY, GET_PROGRAMS, MODAL_EVERY_FOUR_DAYS, USER_LOADED, USER_LOADING } from "../types";
import { updateCurrentUser } from "./authActions";

export const getPrograms =
  ({ currentPrograms, completedPrograms, currentProgramDay }) =>
  dispatch => {
    dispatch({ type: GET_PROGRAMS, payload: currentPrograms });
    dispatch({ type: GET_COMPLETED_PROGRAMS, payload: completedPrograms });
    dispatch({ type: GET_CURRENT_PROGRAM_DAY, payload: currentProgramDay });
  };

export const markAsCompleteProgram = (programSlug, day) => async dispatch => {
  dispatch({ type: USER_LOADING });
  try {
    await memberProgram.put(programSlug, { dayCompleted: day });
    dispatch(updateCurrentUser());
  } catch {
    dispatch({ type: USER_LOADED });
  }
};

export const setShowEveryFourModal = (show: boolean) => {
  return {
    type: MODAL_EVERY_FOUR_DAYS,
    payload: {
      show,
    },
  };
};
